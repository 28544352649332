<template>
  <div class="auth-wrapper auth-v2" :style="backgroundStyle">
    <b-spinner v-if="isLoading" type="grow" class="mt-5 mx-auto d-block" />
    <b-row v-else class="auth-inner m-0" align-v="center">
      <!-- Login-->
      <b-col
        cols="12"
        md="6"
        offset-md="3"
        class="d-flex align-items-center p-0 my-0 col"
      >
        <b-card class="px-3 px-md-2 mx-auto my-3 auth-bg w-100">
          <safe-img :src="communityLogo" class="login-logo mb-3 mt-2 round" />
          <h1 class="font-weight-bold w-100 text-center mb-2" title-tag="h1">
            {{ translate(community.name) }}
          </h1>
          <b-card-text class="text-center h4">
            {{ $t("login.subtitle") }}
          </b-card-text>

          <!-- form -->
          <validation-observer v-if="communitySlug !== 'barcelona-activa'" ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('login.form.email.label')"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    :placeholder="$t('login.form.email.placeholder')"
                    :aria-label="$t('login.form.email.label')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('login.form.password.label')"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      :placeholder="$t('login.form.password.placeholder')"
                      :aria-label="$t('login.form.password.label')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                class="mt-1"
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                {{ $t("login.form.action.sign-in") }}
              </b-button>
            </b-form>
          </validation-observer>
          <SSOLogin v-else :community="community" />
          <div
            v-if="
              (community.googleURL != null &&
                oAuthApps != null &&
                oAuthApps.apps['92'] != null) ||
                communitySlug === 'barcelona-activa'
            "
            class="text-center mt-2"
          >
            <p>· {{ $t("login.form.action.sign-in-with") }} ·</p>
            <!--class=" mr-1"-->
            <b-button
              v-if="
                community.googleURL != null &&
                  oAuthApps != null &&
                  oAuthApps.apps['92'] != null
              "
              type="submit"
              variant="outline-primary"
              :href="community.googleURL"
              :class="communitySlug === 'barcelona-activa' ? 'mr-3' : ''"
            >
              <b-icon-google />
            </b-button>
            <b-button
              v-if="communitySlug === 'barcelona-activa'"
              type="submit"
              variant="outline-primary"
              :href="ssoURL"
            >
              SSO
            </b-button>
          </div>
          <b-card-text class="text-center mt-2 small mb-0">
            <span>{{ $t("login.forgot-password.message") }}</span>
            <b-link :href="resetPasswordURL">
              <span>&nbsp;{{ $t("login.forgot-password.action") }}</span>
            </b-link>
          </b-card-text>
          <b-card-text v-if="showRegistrationLink" class="text-center small">
            <span>{{ $t("login.sign-up.message") }}</span>
            <b-link :href="signUpURL">
              <span>&nbsp;{{ $t("login.sign-up.action") }}</span>
            </b-link>
          </b-card-text>
          <b-card-text class="text-center login-community mt-3">
            <b-link :href="landingURL" target="_blank">
              <b-img :src="brand" alt="Power by Nectios" class="brand" />
            </b-link>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { getImageResource } from '@/@core/utils/image-utils';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import Service from '@/config/service-identifiers';
import BrandLogo from '@/assets/images/brand/powerby-nectios.svg';
import Vue from 'vue';
import { getNewAuthRoute } from '@/auth/utils';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import SSOLogin from '../../auth/views/SSOLogin.vue';

export default {
  components: {
    SafeImg,
    ValidationProvider,
    ValidationObserver,
    SSOLogin,
  },
  mixins: [togglePasswordVisibility, ToastNotificationsMixin],
  data() {
    return {
      password: '',
      userEmail: '',
      ssoURL: null,

      // validation rules
      required,
      email,

      isLoading: true,
      community: null,

      from: null,
      path: null,
      oAuthApps: null,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
    WBSURL() {
      return `${process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
        '{slug}',
        this.communitySlug,
      ).replace('{subdomain}', this.community.subdomain || 'app')}/SSOLogin`;
    },
    signUpURL() {
      let url = process.env.VUE_APP_SIGN_UP_URL.replace(
        '{subdomain}',
        this.community.subdomain || 'app',
      ).replace('{slug}', this.communitySlug);
      url = `${url}?code=${this.community.language}`;
      return url;
    },
    resetPasswordURL() {
      return process.env.VUE_APP_RESET_PASSWORD_URL.replace(
        '{subdomain}',
        this.community.subdomain || 'app',
      ).replace('{slug}', this.communitySlug);
    },
    termsConditionsURL() {
      return (
        `${process.env.VUE_APP_LEGAL_CENTER_URL.replace(
          '{subdomain}',
          this.community?.subdomain || 'app',
        ).replace('{slug}', this.communitySlug)}/?legal=terms_conditions`
      );
    },
    privacyPolicyURL() {
      return (
        `${process.env.VUE_APP_LEGAL_CENTER_URL.replace(
          '{subdomain}',
          this.community?.subdomain || 'app',
        ).replace('{slug}', this.communitySlug)}/?legal=privacy_policy`
      );
    },
    showRegistrationLink() {
      if (this.community.accessibility > 2) {
        return false;
      }

      return true;
    },
    backgroundStyle() {
      const backgrounds = this.community?.customization?.theme?.login?.backgrounds || [];

      if (backgrounds.length) {
        return {
          backgroundImage: `url(${getImageResource(backgrounds[0])}`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat !important',
        };
      }

      return null;
    },
    communityLogo() {
      return getImageResource(
        this.community.customization?.theme?.login?.logo
          || this.community?.logoURL
          || this.community.header?.logo_url,
      );
    },
    brand() {
      return BrandLogo;
    },
    landingURL() {
      return process.env.VUE_APP_LANDING_URL_COMMUNITY;
    },
  },
  watch: {
    communitySlug() {
      this.updateCommunity();
    },
    setLocale(newLocale) {
      this.$store.dispatch('setLocale', newLocale);
    },
  },
  async created() {
      if (process.env.VUE_APP_ENV !== 'development') {
        window.location.href = getNewAuthRoute({
          slug: this.$route.params.communityId || "",
          subdomain: window.location.host.split(".")[0] !== "app" ? `${window.location.host.split(".")[0]}.` : null,
        });
        return;
      } else {
        await this.updateCommunity();
        this.isLoading = false;
      }
      await this.getSSOLogin();

  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },
    async updateCommunity() {
      this.isLoading = true;

      const response = await this.$store.$service[Service.BackendClient].get(
        'login',
        {
          params: { communitySlug: this.communitySlug },
        },
      );
      this.community = response.data.data;
      /* Community colors */
      if (this.community.community_customizations.theme) {
        const customization = this.community.community_customizations.theme;
        if (customization.primaryColor) {
          document.documentElement.style.setProperty(
            '--primary-color',
            customization.primaryColor,
          );
          let hsl = customization.primaryColor;
          hsl = hsl.substring(4, hsl.length - 1);
          hsl = hsl.split(',');
          document.documentElement.style.setProperty(
            '--primary-color-h',
            hsl[0],
          );
          document.documentElement.style.setProperty(
            '--primary-color-s',
            hsl[1],
          );
          document.documentElement.style.setProperty(
            '--primary-color-l',
            hsl[2],
          );
        }
        if (customization.secondaryColor) {
          document.documentElement.style.setProperty(
            '--secondary-color',
            customization.secondaryColor,
          );
          let hslSecondary = customization.secondaryColor;
          hslSecondary = hslSecondary.substring(4, hslSecondary.length - 1);
          hslSecondary = hslSecondary.split(',');
          document.documentElement.style.setProperty(
            '--secondary-color-h',
            hslSecondary[0],
          );
          document.documentElement.style.setProperty(
            '--secondary-color-s',
            hslSecondary[1],
          );
          document.documentElement.style.setProperty(
            '--secondary-color-l',
            hslSecondary[2],
          );
        }
      }
      /* Finish colors */
      const oauth = await this.$store.$service[Service.BackendClient].get(
        'authApps',
        {
          params: { communitySlug: this.communitySlug },
        },
      );
      this.oAuthApps = oauth.data;

      // if (this.community.showChatbot === 1) {
      //   const chatBot = document.createElement('script');
      //   chatBot.innerText = 'window.$crisp=[];window.CRISP_WEBSITE_ID="a1aa36a2-c0e5-44a4-bc75-c5f95f1af801";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();';
      //   document.body.appendChild(chatBot);
      // }
      if (this.community.showChatbot === 2) {
        const chatBot = document.createElement('div');
        chatBot.innerHTML = this.$store.getters.currentCollective?.customChatbot;
        document.body.appendChild(chatBot);
      }

      if (
        this.community.mainCommunitySlug
        && this.community.mainCommunitySlug !== this.communitySlug && !this.community.bypass
      ) {
        this.from = this.communitySlug;
        this.path = this.$route.params.path;
        await this.$router.replace({
          name: 'auth-login',
          params: { communityId: this.community.mainCommunitySlug },
        });

        return;
      }

      if (this.community.headMeta != null) {
        const metadata = this.community.headMeta;

        document.getElementsByName('author').content = metadata.name_author;

        document.getElementsByName('description').content = metadata.name_description;

        document.getElementsByName('keywords').content = metadata.name_keywords;

        document.getElementsByName('twitter:title').content = metadata.name_twitter_title;

        document.getElementsByName('twitter:description').content = metadata.name_twitter_description;

        document.getElementById('ogtitle').content = metadata.property_og_title;

        document.getElementById('ogdescription').content = metadata.property_og_description;
      }

      // this.isLoading = false;
      this.setLocale();
    },
    async getSSOLogin() {
      const response = await this.$store.$service[Service.BackendClient].get(
        'loginmsal',
        {
          params: { communitySlug: this.communitySlug },
        },
      );
      this.ssoURL = response.data.url;
    },
    async login() {
      const success = await this.$refs.loginForm.validate();
      if (success) {
        try {
          await this.$store.dispatch('login', {
            email: this.userEmail,
            password: this.password,
            communitySlug: this.communitySlug,
          });

          if (
            this.community.mainCommunitySlug
            && this.community.mainCommunitySlug !== this.communitySlug && !this.community?.bypass
          ) {
            return this.$router.replace({
              name: this.path,
              params: { communityId: this.from },
            });
          }
          if (Vue.$cookies.get('lastUrl')) {
            const url = Vue.$cookies.get('lastUrl');
            Vue.$cookies.remove('lastUrl');
            Vue.$cookies.remove('registeredTo');
            window.location.replace(url);
          }

          return this.$router.replace({
            name: 'Resumen',
            params: { communityId: this.$route.params.communityId },
          });
        } catch (error) {
          if (error.response.status === 401) {
            this.$refs.loginForm.setErrors({
              password: this.$t('login.form.invalid-credentials'),
            });
          } else {
            this.notifyError(this.$t('error-message.general-error'));
          }
        }
      }
      return '';
    },
    setLocale() {
      this.$store.dispatch('setLocale', this.community.language);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-auth.scss";
.login-logo {
  width: 120px;
  margin: auto;
  display: block;
}
.brand {
  height: 32px;
}
.community-text {
  padding-left: 6px;
  margin-top: 4px;
  vertical-align: bottom;
  color: black;
}
@media (max-width: 767px) {
  .card {
    height: 100%;
    border-radius: 0;
  }
  .col {
    height: 100vh;
  }
}
</style>
