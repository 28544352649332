<template>
          <!-- form -->
          <validation-observer ref="loginForm" #default="{invalid}">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- user -->
              <b-form-group label="" label-for="login-user">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('login.form.username.label')"
                  vid="user"
                  rules="required"
                >
                  <b-form-input
                    id="login-user"
                    v-model="username"
                    :state="errors.length > 0 ? false : null"
                    name="login-user"
                    :placeholder="$t('login.form.username.placeholder')"
                    :aria-label="$t('login.form.username.label')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('login.form.password.label')"
                  vid="password"
                  rules="required"
                >
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      :placeholder="$t('login.form.password.placeholder')"
                      :aria-label="$t('login.form.password.label')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                class="mt-1"
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                {{ $t('login.form.action.sign-in') }}
              </b-button>
            </b-form>
          </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { getImageResource } from '@/@core/utils/image-utils';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import Service from '@/config/service-identifiers';
import BrandLogo from '@/assets/images/brand/powerby-nectios.svg';
import Vue from 'vue';

export default {
  name: "SSOLogin",
  components: {
    SafeImg,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility, ToastNotificationsMixin],
  props: {
    community: {
      required: true
    }
  },
  data() {
    return {
      password: '',
      username: '',
      ssoURL: null,

      // validation rules
      required,

      isLoading: false,

      from: null,
      path: null,
    };
  },
  computed: {
    loginURL() {
      return process.env.VUE_APP_LOGIN_TEMPLATE_URL_OLD.replace(
        "{collectiveSubdomain}",
        this.community?.subdomain || "app"
      ).replace("{collectiveSlug}", this.communitySlug);
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    communitySlug() {
      return this.$route.params.communityId;
    },

    backgroundStyle() {
      const backgrounds = this.community?.customization?.theme?.login?.backgrounds || [];

      if (backgrounds.length) {
        return {
          backgroundImage: `url(${getImageResource(backgrounds[0])}`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat !important',
        };
      }

      return null;
    },
    communityLogo() {
      return getImageResource(
        this.community.customization?.theme?.login?.logo || this.community?.logoURL || this.community.header?.logo_url,
      );
    },
    brand() {
      return BrandLogo;
    },
  },
  watch: {
    communitySlug() {
      this.updateCommunity();
    },
    setLocale(newLocale) {
      this.$store.dispatch('setLocale', newLocale);
    },
  },
  methods: {
    async login() {
      const success = await this.$refs.loginForm.validate();
      if (success) {
        try {
          await this.$store.dispatch('loginsoap', {
            user: this.username,
            password: this.password,
            communitySlug: this.communitySlug,
          });
          if (this.community.mainCommunitySlug && this.community.mainCommunitySlug !== this.communitySlug) {
            return this.$router.replace({ name: this.path, params: { communityId: this.from } });
          }
          if (Vue.$cookies.get('lastUrl')) {
            const url = Vue.$cookies.get('lastUrl');
            Vue.$cookies.remove('lastUrl');
            Vue.$cookies.remove('registeredTo');
            window.location.replace(url);
          }
          return this.$router.replace({ name: 'Resumen', params: { communityId: this.$route.params.communityId } });
        } catch (error) {
          if (error.response.status === 401) {
            this.$refs.loginForm.setErrors({ password: this.$t('login.form.invalid-credentials') });
          } else {
            this.notifyError(this.$t('error-message.general-error'));
          }
        }
      }
      return '';
    },
    setLocale() {
      this.$store.dispatch('setLocale', this.community.language);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';
.login-logo {
  width: 120px;
  margin: auto;
  display: block;
}
.brand {
  height: 32px;
}
.community-text {
  padding-left: 6px;
  margin-top: 4px;
  vertical-align: bottom;
  color: black;
}
@media (max-width: 767px) {
  .card {
    height: 100%;
    border-radius: 0;
  }
  .col {
    height: 100vh;
  }
}
</style>
