var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2",style:(_vm.backgroundStyle)},[(_vm.isLoading)?_c('b-spinner',{staticClass:"mt-5 mx-auto d-block",attrs:{"type":"grow"}}):_c('b-row',{staticClass:"auth-inner m-0",attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"d-flex align-items-center p-0 my-0 col",attrs:{"cols":"12","md":"6","offset-md":"3"}},[_c('b-card',{staticClass:"px-3 px-md-2 mx-auto my-3 auth-bg w-100"},[_c('safe-img',{staticClass:"login-logo mb-3 mt-2 round",attrs:{"src":_vm.communityLogo}}),_c('h1',{staticClass:"font-weight-bold w-100 text-center mb-2",attrs:{"title-tag":"h1"}},[_vm._v(" "+_vm._s(_vm.translate(_vm.community.name))+" ")]),_c('b-card-text',{staticClass:"text-center h4"},[_vm._v(" "+_vm._s(_vm.$t("login.subtitle"))+" ")]),(_vm.communitySlug !== 'barcelona-activa')?_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.login($event)}}},[_c('b-form-group',{attrs:{"label":"","label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":_vm.$t('login.form.email.label'),"vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-email","state":errors.length > 0 ? false : null,"name":"login-email","placeholder":_vm.$t('login.form.email.placeholder'),"aria-label":_vm.$t('login.form.email.label')},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('login.form.password.label'),"vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":_vm.$t('login.form.password.placeholder'),"aria-label":_vm.$t('login.form.password.label')},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{staticClass:"mt-1",attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t("login.form.action.sign-in"))+" ")])],1)]}}],null,false,2371091184)}):_c('SSOLogin',{attrs:{"community":_vm.community}}),(
            (_vm.community.googleURL != null &&
              _vm.oAuthApps != null &&
              _vm.oAuthApps.apps['92'] != null) ||
              _vm.communitySlug === 'barcelona-activa'
          )?_c('div',{staticClass:"text-center mt-2"},[_c('p',[_vm._v("· "+_vm._s(_vm.$t("login.form.action.sign-in-with"))+" ·")]),(
              _vm.community.googleURL != null &&
                _vm.oAuthApps != null &&
                _vm.oAuthApps.apps['92'] != null
            )?_c('b-button',{class:_vm.communitySlug === 'barcelona-activa' ? 'mr-3' : '',attrs:{"type":"submit","variant":"outline-primary","href":_vm.community.googleURL}},[_c('b-icon-google')],1):_vm._e(),(_vm.communitySlug === 'barcelona-activa')?_c('b-button',{attrs:{"type":"submit","variant":"outline-primary","href":_vm.ssoURL}},[_vm._v(" SSO ")]):_vm._e()],1):_vm._e(),_c('b-card-text',{staticClass:"text-center mt-2 small mb-0"},[_c('span',[_vm._v(_vm._s(_vm.$t("login.forgot-password.message")))]),_c('b-link',{attrs:{"href":_vm.resetPasswordURL}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("login.forgot-password.action")))])])],1),(_vm.showRegistrationLink)?_c('b-card-text',{staticClass:"text-center small"},[_c('span',[_vm._v(_vm._s(_vm.$t("login.sign-up.message")))]),_c('b-link',{attrs:{"href":_vm.signUpURL}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("login.sign-up.action")))])])],1):_vm._e(),_c('b-card-text',{staticClass:"text-center login-community mt-3"},[_c('b-link',{attrs:{"href":_vm.landingURL,"target":"_blank"}},[_c('b-img',{staticClass:"brand",attrs:{"src":_vm.brand,"alt":"Power by Nectios"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }